import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect, BrowserRouter as Router, Route, Switch} from 'react-router-dom'
// import {createBrowserHistory} from 'history'

import Headers from './Headers'
import './index.scss'
import './home.scss'
import AutoMenu from '../../react/AutoMenu'

import Order from './Order'
import Sorry from './Sorry'
import Form from './Form'
import Franchise from './Franchise'
import Catering from './Catering'
import FormFranchise from './FormFranchise'
import CateringForm from './CateringForm'
import {SiteNotice} from '../../Notices/SiteNotice'
import Press from './Press'
import GiftCards from './GiftCards'
import Promotion from './Promotion'
import Vote from './Vote'

import locationsInfo from './locations'
import {RootRouterContext} from '../../index.js'
import {ShopDataContext} from '../../Context/ShopData'


import CartBar from './CartBar'

export const StickyTopBar = () => { return null }



export const locations = [
  {shopId: "pablitostacosburbank",  basePath:  "/burbank"},
  {shopId: "pablitostacosnoho",     basePath:     "/noho"},
  {shopId: "pablitostacosnoho",     basePath:     "/nohoPizza"},
  {shopId: "pablitostacosnoho",     basePath:     "/hamburguetza"},
  {shopId: "pablitosevent",     basePath:     "/night", cssGlobalPrefix: 'pablitostacosburbank'},
  {shopId: "pablitostacosstudio",   basePath:   "/studio", cssGlobalPrefix: 'pablitostacosburbank'},
  {shopId: "pablitostacosvictory",  basePath:  "/victory"},
  {shopId: "pablitostacosmoorpark", basePath: "/moorpark"},
  {shopId: "pablitostacosvannuys",  basePath:  "/vannuys"},
  {shopId: "pablitostacosdtla",  basePath:  "/dtla"},
  {shopId: "pablitostacosnorth",    basePath:  "/popup"},
  {shopId: "pablitostacosnorth",    basePath:  "/northhills"},
  {shopId: "pablitostacosnorth",    basePath:  "/palmdale"},
  {shopId: "pablitosmarket",        basePath:  "/market", cssGlobalPrefix: 'pablitostacosburbank'},
  {shopId: "pablitostacosnorth",        basePath:  "/shermanoaks", cssGlobalPrefix: 'pablitostacosburbank'},
]

// function navigateToHome (e) {
//   e.preventDefault()
//   const rootHistory = createBrowserHistory()
//   rootHistory.push('/')
// }

export const pages = [
  // {path: "/", external: true,  label: "All Locations"},
  {path: "/menu", label: "Menu"},
  
  // {path: "/merch", label: "Merch", component: Merch},
  {path: "/press", label: "Press", component: Press, hideFromNav: true},
  {path: "/sorry", hideFromNav: true, component: Sorry},
  {path: "/reward", label: "-", hideFromNav: true, component: Promotion},
  {path: "/gift-cards/:status", hideFromNav: true, label: "Gift Cards", component: GiftCards },
  {path: "/gift-cards", label: "Gift Cards", component: GiftCards },
]

export const customOrderAppPaths = ["/menu", "/order", "/burbank/order"]


const BottomBar = function () {
  return(
    <div className="bottom-bar">
      
    </div>
  )
}

export function SiteNav ({shopId}) {
    const {rootHistory} = useContext(RootRouterContext)
    // console.log('rootrouter: ', rootRouter)

    return (
      <>
      <CartBar shopId={shopId}></CartBar>
    <nav className="pages-nav">
      <ul>
        <li><a className="page-link" href="/" onClick={(e) => {
          e.preventDefault()
          rootHistory.push('/')
        }}>All Locations</a></li>
        {pages
          .filter(({hideFromNav}) => !hideFromNav)
          .map(({path, label, external, onClick}) => (
          <li key={path}>
            {external ?
              <a       className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        ))}
      </ul>
    </nav>
  </>
  )
}




export class Header extends PureComponent {

  render() {
    console.log(this.props)
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">

          <div className="text">
            <div className="hero-logo">
              <img src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/q_82,w_400/v1551736184/danstuff/pablitos-tacos-cut.png" alt=""/>
            </div>

            



            {((this.props.shopId === 'pablitostacosstudio')
            // || (this.props.shopId === 'pablitostacosburbank')
            // || (this.props.shopId === 'pablitostacosnoho')
            // || (this.props.shopId === 'pablitostacosmoorpark')
            || (this.props.shopId === 'pablitostacosvictory'))
            &&
              <div>
                {/* <a href="https://pablitostacos.com"></a> */}
                <p>Moved to 11502 Ventura Boulevard</p>
                <p>View  <a href="https://pablitostacos.com/popup">Location Info</a></p>
              </div>

            }


            {
              // (this.props.shopId !== 'pablitostacosnoho') &&
              (this.props.shopId !== 'pablitostacosstudio')
            && (this.props.shopId !== 'pablitostacosvictory')
            // && (this.props.shopId === 'pablitostacosburbank')
            // && (this.props.shopId !== 'pablitostacosmoorpark')
            && <div className="info">
              {locationsInfo
                .filter((l) => l.id === this.props.shopId)
                .slice(0,1)
                .map(({label, address, phone, hours, special, phoneLink, className=""}) => (
                  <div className={"location"}>
                    <div className="content">
                      {address && <div className="address">
                        {address[0]} <br/>
                        {address[1]}
                      </div>}
                      {phone && <div className="phone">
                        <a
                          className="phone-link"
                          href={`tel:${phoneLink}`}>{phone}</a>
                      </div>}
                      {hours && <ul className="hours-of-operation">
                        {hours.map((hours) => {
                          return (<li key={hours.day}>
                            <span className="day">{hours.day}</span>
                            <span className="hours">{hours.hours}</span>
                          </li>)
                        })}
                      </ul>}
                    </div>
                  </div>
              ))}

              <div className="order-action">
                <a href="#order" className="order-button">Order Contact Free</a>
                {(this.props.shopId !== 'pablitosmarket') && <p>pickup / Delivery</p>}
              </div>


            </div>}


          </div>


        

        

        </div>
        {(false) &&
              <div style={{
                display: 'flex',
                padding: "1em 0.5em",
                justifyContent: 'center',
                alignItems: "center",
                background: "black",
                color: "white"
                }}>
                {/* <img 
                style={{
                  display: 'block',
                  width: "100%",
                  maxWidth: "250px"
                }}
                src="https://afag.imgix.net/pablitos-tacos-burbank/fire.png?w=500&auto=format" alt="" /> */}


                <div style={{maxWidth: "700px", paddingLeft: "1em"}} className="text">
                  <h3 style={{textAlign: "left"}}>Help Us Feed 1000 Families in Peru</h3>
                  <p>—Donations Matched by Pablito's Tacos! 🌮🇵🇪 Danny Rodriguez and his father have been sustaining community feeding centers for 5 years. This year, we aim to raise $5000 to nourish 1000 families. Every donation will be doubled by Pablito's Tacos. Stay tuned for update videos on September 26th. Every dollar makes a difference. <a style={{color: "red"}} href="https://www.gofundme.com/f/help-1000-families-have-meals-in-peru?utm_medium=email&utm_source=product&utm_campaign=p_email%2B2300-co-team-welcome">Donate on GoFundMe now!</a></p>
                  {/* <p><a href="https://www.instagram.com/p/CtrQBUcLIg6/">image from @dadjack</a></p> */}
                </div>
              </div>
            }

       
       

        <h2 id="order" style={{visibility: 'hidden', height: "0px", margin: "0", padding: "0"}}>Order Online</h2>


                      

        {(this.props.basePath === '/nohoPizza') && 
          
          
            <div className="ordering-menu">

      

              <AutoMenu
              subMenuFilter={subMenuFilter}
              tagsToShow={['pizza']}></AutoMenu>
            <AutoMenu
              subMenuFilter={(subMenu) => {
                const isShownNormally = subMenuFilter(subMenu)
                const tag = subMenu.tag || ''
            
                const isHidden = tag.indexOf('hidden') !== -1
                const isPizza = tag.indexOf('pizza') !== -1
                const isBreakfast = tag.indexOf('noho-breakfast') !== -1
            
                if (isPizza ) {
                  return false
                }
                return isShownNormally
              }}
              ></AutoMenu>
            </div>
        }


        {(this.props.basePath === '/hamburguetza') && 
          
          
          <div className="ordering-menu">

    

            <AutoMenu
            subMenuFilter={subMenuFilter}
            tagsToShow={['hamburguetza']}></AutoMenu>
          <AutoMenu
            subMenuFilter={(subMenu) => {
              const isShownNormally = subMenuFilter(subMenu)
              const tag = subMenu.tag || ''
              const isBreakfast = tag.indexOf('noho-breakfast') !== -1

              const isHidden = tag.indexOf('hidden') !== -1
              const isPizza = tag.indexOf('hamburguetza') !== -1
          
              if (isPizza) {
                return false
              }
              return isShownNormally
            }}
            ></AutoMenu>
          </div>
      }

     


        {(this.props.basePath !== '/nohoPizza') && 
          
          <div className="ordering-menu">

      {/* {(this.props.basePath === '/noho') &&
      <div 
      style={{
        background: 'black',
        paddingBottom: "3em",
        textAlign: 'center',
        lineHeight: "1.3em",
        fontWeight: 800,
      }}
      className="promo">
        <p

        >🆕 Check out the new <br/>Hamburguetza <br/>Angus Burger & Sandwich<br/>sections at the bottom</p>
      </div>
      } */}
            
          

            <AutoMenu 
            subMenuFilter={subMenuFilter}
            ></AutoMenu>

          </div>
        }


        

        



      </div>
    );
  }
}

const mainNav = [
  {path: '/', label: "Home"},
  // {path: '/gift-cards', label: "Gift Cards"},
  {path: '/about-us', label: "About Us"},
  {path: 'https://pablitostacosfranchise.com', external: true, label: "Franchise"},
  {path: '/catering', label: "Catering"},
  // {path: "/vote", label: "Vote", component: Vote},

]

export function MultilocationHome () {
  
  const logoImage = `https://afag.imgix.net/pablitos-tacos-burbank/california-burrito-new.png?h=200`
  const circleImage = `https://afag.imgix.net/pablitos-tacos-burbank/logo.png?w=200`
  // const {menu, settings} = useContext(ShopDataContext)
  // console.log('>>>  settings', settings)
  return (
    <div className="site-content pablitostacos">

      <div className="main-page cart-wrapper">

        <div className="cart minimized">
          <div className="home-link-wrapper">
            <Link to="/" className="home-link">
              <img
                className="nav-bar-logo"
                // style={{ height: "50px"}}
                  src={logoImage} alt={"Pablitos Tacos"}/>
            </Link>

          </div>

          </div>

        </div>
        <nav className="main pages-nav">
          <ul>
            {mainNav
              .filter(({hideFromNav}) => !hideFromNav)
              .map(({path, label, external, onClick}) => (
              <li key={path}>
                {external ?
                  <a       className="page-link" href={path}>{label}</a> :
                  <NavLink className="page-link" exact to={path}>{label}</NavLink>}
              </li>
            ))}
          </ul>
        </nav>
      {/* <div className="home-nav">
        <div className="logo">
          <img src={circleImage} alt=""/>
        </div>
        <div className="nav-items">
          <NavLink to="/locations">Order</NavLink>
          <NavLink to="/press">Press</NavLink>
        </div>
      </div> */}

      <Switch>
        <Route path="/about-us" component={Press} />
        <Route path="/vote" component={Vote} />
        <Route path="/franchise" component={Franchise} />
        <Route path="/catering" component={Catering} />


        <LandingPage></LandingPage>
      </Switch>

      <FormFranchise hide={true}></FormFranchise>
      <CateringForm hide={true}></CateringForm>
    </div>
  );
}


function LandingPage () {
  return(


      <div className="landing-page-wrapper">
        <Headers></Headers>
{/* 
        <SiteNotice
          orderLink="/#locations"
          noDrinks={true}
           forceShow={"All Day Breakfast @ Burbank!"}></SiteNotice> */}


        <div className="franchise">
          <a href="https://pablitostacosfranchise.com">
            <img style={{display: 'block'}} src="https://afag.imgix.net/pablitos-tacos-burbank/franchise.heic?w=2000&auto=format" alt="" />
          </a>
        </div>

        <div className="section">
          {/* <header className="locations-header">
            <div className="site-logo">
              <img src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/q_82,w_400/v1551736184/danstuff/pablitos-tacos-cut.png?width=150" alt=""/>
            </div>



            <div className="header">

         
            </div>

       
          </header> */}

          {/* <section className="featured">
            <article className="product">
              <div className="photo"></div>
              <div className="details">
                <h3></h3>
              </div>
            </article>
          </section> */}



          <header className="location-header">
            <h2 id="locations">Locations</h2>
          
          </header>
          <div className="locations">


            {locationsInfo.map(({cashback, id, tax, type, external, link, disabled, openingDate, special, label, buttonLabel, address, phone, hours, phoneLink, className=""}) => (
              <div className={"grid-item location " + className}>
                <div className="content">
                  <h3 className="title">{label}</h3>
                  {type && <div className="type">{type}</div>}
                  <div className="order-actions">
                    {/* {(id === 'pablitostacosnoho') && <div>Closed Today</div>} */}
                    {disabled ?
                      <Link to="#" className="order-button disabled">{openingDate}</Link> :
                      external ? 
                      <a className="order-button" href="https://pablitosworld.com">{"Visit Site"}</a>
                      :
                      <Link to={link} className="order-button">{buttonLabel || "Order Pickup/Delivery"}</Link>
                    }
                  </div>

                  {/* <div>
                    We Do Curbside!
                  </div> */}



                  {special && <div className="specials">
                    <span className="specials-text">{special}</span>
                  </div>}
                  {cashback && <div className="specials">
                    <span className="specials-text">{cashback}% cashback</span>
                  </div>}


                  {tax && <div className="tax">
                    <span className="specials-text">{tax}</span>
                  </div>}



                  {address && <div className="address">
                    {address[0]} <br/>
                    {address[1]}
                  </div>}
                  {phone && <div className="phone">
                    <a
                      className="phone-link"
                      href={`tel:${phoneLink}`}>{phone}</a>
                  </div>}

                  {hours && <ul className="hours-of-operation">
                    {hours.map((hours) => {
                      return (<li key={hours.day}>
                        <span className="day">{hours.day}</span>
                        <span className="hours">{hours.hours}</span>
                      </li>)
                    })}
                  </ul>}
                </div>
              </div>
            ))}


<Form></Form>
          </div>

       
{/*
          <div
            style={{
              maxWidth: "800px",
              margin: "0 auto"
            }}
             className="event">
             <div
               style={{
                 margin: "1em 0.5em"
               }}
               className="header">
               <h2
                 >Live-Stream Event</h2>
                 <h3
                   style={{
                     fontSize: "1.3em",
                     padding: "0"
                   }}
                   >Signed Poster with Delivery Over $40<br/>(Burbank Location Only)</h3>
                 <h3
                   style={{
                     fontSize: "1.3em",
                            padding: "0",
                            color: "red"
                   }}
                   >CONTACT-FREE!</h3>

                   <p>Instagram <a href="https://www.instagram.com/pablitostacos/?hl=en">@pablitostacos</a> </p>
                   <p>Facebook <a href="https://www.facebook.com/pablitostacos818/">@pablitostacos818</a></p>

                  <h3><Link
                    style={{
                      fontSize: "1.3em",
                             padding: "0",
                             color: "red"
                    }}
                     to="/burbank/merch">Buy Merch<br/>Support Local Artists</Link></h3>
             </div>
            <img
              style={{
                width: "100%"
              }}
              src="https://afag.imgix.net/pablitos-tacos-burbank/live-event.jpg?w=1800&auto=format" alt=""/>
          </div> */}


          {/* <div className="old-locations">
            <h2>Other Locations</h2>
            <p><strong>Studio City,</strong> <strong>Oyster House</strong> &<strong> Western Tire</strong> locations are closed, please choose a location above.</p>
          </div> */}


        </div>
      </div>

  )
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header-wrapper">
        {/* <div className="ordering-header">

          <div
            className="text"
             >
            <h2>Pablito's Rewards</h2>
            <p>5% Cashback on togo and delivery orders.</p>
          </div>
        </div> */}
      </div>
    );
  }
}


export function subMenuFilter (subMenu) {
  const tag = subMenu.tag || ''
  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isAllDayBreakfastMenu = tag.indexOf('breakfast') !== -1
  const isHidden = tag.indexOf('hidden') !== -1

  if (tag.indexOf('thursdays-only') !== -1) {
    // thursday promo category
    if (day === 4) {
      return true
    } else {
      return false
    }
  }
  if (tag.indexOf('tuesday-only') !== -1) {
    // thursday promo category
    if (day === 2) {
      return true
    } else {
      return false
    }
  }
  if (tag.indexOf('noho-breakfast') !== -1) {
    if ((day === 1) || (day === 2)) {
      if ((hours > 0) && (hours < 16)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  if (tag.indexOf('noho-dinner') !== -1) {
    return true
    // thursday promo category
    // if ((day === 1) || (day === 2)) {
    //   if ((hours > 16)) {
    //     return true
    //   } else {
    //     return false
    //   }
    // } else {
    //   return true
    // }
  }

  if (tag.indexOf('promo-wing-it-wed') !== -1) {
    // wednesday promo category
    if (day === 3) {
      return true
    } else {
      return false
    }
  }

  if (isHidden) {
    return false
  }

  if (isAllDayBreakfastMenu && isWeekday) {
    return false
  }

  return true
}

const foodImages = [
  {
    url: "https://afag.imgix.net/pablitos-tacos-burbank/logo.jpg"
  },
  {
    url: "https://afag.imgix.net/pablitos-tacos-burbank/tacos-5.jpg"
  },
  {
    url: "https://afag.imgix.net/pablitos-tacos-burbank/tacos-2.jpg"
  },
  {
    url: "https://afag.imgix.net/pablitos-tacos-burbank/tacos-3.jpg"
  },
  {
    url: "https://afag.imgix.net/pablitos-tacos-burbank/tacos-4.jpg"
  },
]
